@import "../core/variables/variables";
@import "../core/variables/app-variables";

.faq-wrapper {
  .faq-bg {
    background: url(../../../assets/img/pages/medicine-2023.jpg);
    background-position: top;
    background-size: cover !important;
  }
  .faq-search {
    // search input
    .faq-search-input {
      margin-bottom: 1.25rem;
      input {
        background-color: $white;
      }
    }
    @include media-breakpoint-down(md) {
      .faq-search-input {
        width: 75% !important;
      }
    }
    @include media-breakpoint-down(sm) {
      .faq-search-input {
        width: 100% !important;
      }
    }
    // search button
    button {
      right: 0.4rem;
      top: 0.36rem; 
    }
    @include media-breakpoint-down(xs) {
      button {
        padding: 0.65rem 1.3rem;
      }
    }
  }
  // Slide content show/hide
  .faq {
    .wrapper-content{
      display: none;
      &.active{
        display: block;
      }
      .collapse-icon.accordion-icon-rotate:not(.left) .card-header {
        padding-right: 3.5rem !important;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .faq-title{
      font-size: $h2-font-size;
    }
    .faq-subtitle{
      font-size: $h4-font-size;
    }
  }
}
